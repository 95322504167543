import React from "react";
import { Link, withRouter } from "react-router-dom";
import classnames from "classnames";
import SideMenuGroup from "./SideMenuGroup";
import { Badge } from "reactstrap";
import { ChevronRight, LogOut } from "react-feather";
import { history } from "../../../../../history";
import { projectContext } from "../../../../../utility/context/projectSelect";
import { withTranslation } from "react-i18next";
import { ApiService } from "../../../../../service/service.rest";
import * as Icon from "react-feather";
import { collapseSidebar } from "../../../../../redux/actions/customizer";
import * as IconBootstrap from "react-bootstrap-icons";

class SideMenuContent extends React.Component {
  constructor(props) {
    super(props);

    this.parentArr = [];
    this.collapsedPath = null;
    this.redirectUnauthorized = () => {
      history.push("/misc/not-authorized");
    };
  }
  state = {
    flag: true,
    isHovered: false,
    activeGroups: [],
    currentActiveGroup: [],
    tempArr: [],
    menuList: [],
    role_idx: 0,
  };

  static contextType = projectContext;

  handleGroupClick = (id, parent = null, type = "") => {
    let open_group = this.state.activeGroups;
    let active_group = this.state.currentActiveGroup;
    let temp_arr = this.state.tempArr;
    // Active Group to apply sidebar-group-active class
    if (type === "item" && parent === null) {
      active_group = [];
      temp_arr = [];
    } else if (type === "item" && parent !== null) {
      active_group = [];
      if (temp_arr.includes(parent)) {
        temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length);
      } else {
        temp_arr = [];
        temp_arr.push(parent);
      }
      active_group = temp_arr.slice(0);
    } else if (type === "collapse" && parent === null) {
      temp_arr = [];
      temp_arr.push(id);
    } else if (type === "collapse" && parent !== null) {
      if (active_group.includes(parent)) {
        temp_arr = active_group.slice(0);
      }
      if (temp_arr.includes(id)) {
        // temp_arr.splice(temp_arr.indexOf(id), 1)
        temp_arr.splice(temp_arr.indexOf(id), temp_arr.length);
      } else {
        temp_arr.push(id);
      }
    } else {
      temp_arr = [];
    }

    if (type === "collapse") {
      // If open group does not include clicked group item
      if (!open_group.includes(id)) {
        // Get unmatched items that are not in the active group
        let temp = open_group.filter(function (obj) {
          return active_group.indexOf(obj) === -1;
        });
        // Remove those unmatched items from open group
        if (temp.length > 0 && !open_group.includes(parent)) {
          open_group = open_group.filter(function (obj) {
            return !temp.includes(obj);
          });
        }
        if (open_group.includes(parent) && active_group.includes(parent)) {
          open_group = active_group.slice(0);
        }
        // Add group item clicked in open group
        if (!open_group.includes(id)) {
          open_group.push(id);
        }
      } else {
        // If open group includes click group item, remove it from open group
        open_group.splice(open_group.indexOf(id), 1);
      }
    }
    if (type === "item") {
      open_group = active_group.slice(0);
    }

    this.setState({
      activeGroups: open_group,
      tempArr: temp_arr,
      currentActiveGroup: active_group,
    });
  };

  initRender = (parentArr) => {
    this.setState({
      activeGroups: parentArr.slice(0),
      currentActiveGroup: parentArr.slice(0),
      flag: false,
    });
    if (this.state.role_idx !== 0) {
      this.initMenu(this.state.role_idx);
    }
  };

  initMenu = (role_idx) => {
    const getLocalStorageCollapse = window.localStorage.getItem("projectMenuCollapsed");
    collapseSidebar(JSON.parse(getLocalStorageCollapse));
    ApiService.api_menu_list(role_idx).then(
      (response) => {
        if (response.code === "0000") {
          let menus = response.data.map((item) => {
              let icon = null;
              switch (item.menu_key) {
                case "dashboard":
                case "widget":
                case "did_setting":
                  icon = <Icon.Layout size={20} />;
                  break;
                case "alert":
                  icon = <Icon.Bell size={20} />;
                  break;
                case "graph":
                  icon = <Icon.BarChart size={20} />;
                  break;
                case "area":
                  icon = <Icon.Send size={20} />;
                  break;
                case "rule":
                case "offline-rule":
                  icon = <Icon.PlayCircle size={20} />;
                  break;
                case "user_group_setting":
                  icon = <Icon.Users size={20} />;
                  break;
                case "menu_setting":
                  icon = <Icon.Menu size={20} />;
                  break;
                case "user_setting":
                  icon = <Icon.User size={20} />;
                  break;
                case "production":
                  icon = <Icon.Package size={20} />;
                  break;
                case "permission":
                  icon = <IconBootstrap.PersonFillGear size={20} />;
                  break;
                case "menu_permission":
                  icon = <IconBootstrap.Sliders size={20} />;
                  break;
                case "notice":
                  icon = <IconBootstrap.CalendarCheck size={20} />;
                  break;
                case "notice-manage":
                  icon = <IconBootstrap.WindowPlus size={20} />;
                  break;
                case "qna":
                  icon = <IconBootstrap.PencilSquare size={20} />;
                  break;
                case "qna_manage":
                  icon = <IconBootstrap.CardList size={20} />;
                  break;
                default:
                  break;
              }
              return {
                id: item.menu_key,
                title: item.menu_name,
                type: "item",
                icon: icon,
                permissions: ["admin", "editor"],
                navLink: item.menu_path,
                collapsed: JSON.parse(getLocalStorageCollapse) || true,
              };
            })


          this.setState({
            menuList: menus,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  componentDidMount() {
    this.initRender(this.parentArr[0] ? this.parentArr[0] : []);
  }

  componentDidUpdate(prevProps, prevState) {
    const role_idx = this.context.state.user_info.role_idx;
    if (role_idx && this.state.role_idx !== role_idx) {
      this.setState({
        role_idx: role_idx,
      });
      this.initMenu(role_idx);
    }

    if (prevProps.activePath !== this.props.activePath) {
      if (this.collapsedMenuPaths !== null) {
        this.props.collapsedMenuPaths(this.collapsedMenuPaths);
      }
      this.initRender(this.parentArr[0] ? this.parentArr[this.parentArr.length - 1] : []);
    }
  }

  logout = (e) => {
    const t = this.props.t;
    e.preventDefault();
    const contextValue = this.context;
    if (window.confirm(`${t("login_logout_msg")}`)) {
      localStorage.removeItem("uToken");
      localStorage.removeItem("projectArea");
      localStorage.removeItem("projectMenuCollapsed");
      contextValue.selectProject(0, "", 0);
      this.props.history.push("/login");
    }
  };

  isPathActive = (itemId) => {
    const { pathname } = this.props.location;
    const pathSegments = pathname.split('/').filter(segment => segment);
    return pathSegments.includes(itemId);
  };

  render() {
    const t = this.props.t;
    // Loop over sidebar items
    // eslint-disable-next-line
    const menuItems = this.state.menuList.map((item) => {
      const CustomAnchorTag = item.type === "external-link" ? `a` : Link;
      // checks if item has groupheader
      if (item.type === "groupHeader") {
        return (
          <li className="navigation-header" key={`group-header-${item.groupTitle}`}>
            <span>{item.groupTitle}</span>
          </li>
        );
      }

      let renderItem = (
        <li
          className={classnames("nav-item", {
            "has-sub": item.type === "collapse",
            open: this.state.activeGroups.includes(item.id),
            "sidebar-group-active": this.state.currentActiveGroup.includes(item.id) || this.isPathActive(item.id),
            hover: this.props.hoverIndex === item.id,
            active: (this.props.activeItemState === item.navLink && item.type === "item") || (item.parentOf && item.parentOf.includes(this.props.activeItemState)) || this.isPathActive(item.id),
            disabled: item.disabled,
          })}
          key={item.id}
          onClick={(e) => {
            e.stopPropagation();
            if (item.type === "item") {
              this.props.handleActiveItem(item.navLink);
              this.handleGroupClick(item.id, null, item.type);
              if (this.props.deviceWidth <= 1200 && item.type === "item") {
                this.props.toggleMenu();
              }
            } else {
              this.handleGroupClick(item.id, null, item.type);
            }
          }}
        >
          <CustomAnchorTag
            to={item.filterBase ? item.filterBase : item.navLink && item.type === "item" ? item.navLink : ""}
            href={item.type === "external-link" ? item.navLink : ""}
            className={`d-flex ${item.badgeText ? "justify-content-between" : "justify-content-start"}`}
            onMouseEnter={() => {
              this.props.handleSidebarMouseEnter(item.id);
            }}
            onMouseLeave={() => {
              this.props.handleSidebarMouseEnter(item.id);
            }}
            key={item.id}
            onClick={(e) => {
              return item.type === "collapse" ? e.preventDefault() : "";
            }}
            target={item.newTab ? "_blank" : undefined}
          >
            <div className="menu-text">
              {item.icon}
              {/*<span className="menu-item menu-title">{t(item.title)}</span>*/}
              <span className="menu-item menu-title">{item.title}</span>
            </div>

            {item.badge ? (
              <div className="menu-badge">
                <Badge color={item.badge} className="mr-1" pill>
                  {item.badgeText}
                </Badge>
              </div>
            ) : (
              ""
            )}
            {item.type === "collapse" ? <ChevronRight className="menu-toggle-icon" size={13} /> : ""}
          </CustomAnchorTag>
          {item.type === "collapse" ? (
            <SideMenuGroup
              group={item}
              handleGroupClick={this.handleGroupClick}
              activeGroup={this.state.activeGroups}
              handleActiveItem={this.props.handleActiveItem}
              activeItemState={this.props.activeItemState}
              handleSidebarMouseEnter={this.props.handleSidebarMouseEnter}
              activePath={this.props.activePath}
              hoverIndex={this.props.hoverIndex}
              initRender={this.initRender}
              parentArr={this.parentArr}
              triggerActive={undefined}
              currentActiveGroup={this.state.currentActiveGroup}
              permission={this.props.permission}
              currentUser={this.props.currentUser}
              redirectUnauthorized={this.redirectUnauthorized}
              collapsedMenuPaths={this.props.collapsedMenuPaths}
              toggleMenu={this.props.toggleMenu}
              deviceWidth={this.props.deviceWidth}
            />
          ) : (
            ""
          )}
        </li>
      );

      if (item.navLink && item.collapsed !== undefined && item.collapsed === true) {
        this.collapsedPath = item.navLink;
        this.props.collapsedMenuPaths(item.navLink);
      }

      if (
        item.type === "collapse" ||
        item.type === "external-link" ||
        (item.type === "item" && item.permissions && item.permissions.includes(this.props.currentUser)) ||
        item.permissions === undefined
      ) {
        return renderItem;
      } else if (item.type === "item" && item.navLink === this.props.activePath && !item.permissions.includes(this.props.currentUser)) {
        return this.redirectUnauthorized();
      }
    });
    return (
      <React.Fragment>
        {menuItems}
        <li className="nav-item">
          <a href="#" className="d-flex justify-content-start" onClick={(e) => this.logout(e)}>
            <div className="menu-text">
              <LogOut />
              <span>{t("login_logout")}</span>
            </div>
          </a>
        </li>
      </React.Fragment>
    );
  }
}
export default withRouter(withTranslation()(SideMenuContent));
