/*global daum*/
import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {useTranslation} from "react-i18next";

const ConfirmModal = (props) => {
  const { t } = useTranslation();
  const {isOpen, message, buttonTitle, type, command, onClickClose} = props;

return (
  <Modal
    isOpen={isOpen}
    className="modal-dialog-centered dashboard-modal"
  >
    <ModalHeader className="bg-primary">
      INFO
    </ModalHeader>
    <ModalBody>
      <p>{message}</p>
    </ModalBody>
    <ModalFooter>
      <>
        <Button.Ripple onClick={(e) => {onClickClose(null, null)}}>{t("area_cancel")}</Button.Ripple>
        <Button.Ripple color="primary" onClick={(e) => {onClickClose(type, command)}}>
          {buttonTitle}
        </Button.Ripple>
      </>
    </ModalFooter>
  </Modal>
  );
};

export default ConfirmModal;
