import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Layout } from "./utility/context/Layout";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/storeConfig/store";
import Spinner from "./components/@vuexy/spinner/Fallback-spinner";
import { IntlProviderWrapper } from "./utility/context/projectSelect";
import "./index.scss";

import i18n from "./i18n/I18n";
import { I18nextProvider } from "react-i18next";

const LazyApp = lazy(() => import("./App"));

window.onload = () => {
  // console.log("language :: ", window.navigator.language);
  render();
};

// configureDatabase()
function render() {
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      {/* <I18nextProvider i18n={i18n('en-US')}> */}
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <IntlProviderWrapper>
            <Layout>
              <LazyApp />
            </Layout>
          </IntlProviderWrapper>
        </Suspense>
      </Provider>
    </I18nextProvider>,
    document.getElementById("root")
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
