import React, { useContext } from "react";
import { Col, Navbar, Row } from "reactstrap";
import classnames from "classnames";
import Header from "../../../views/pages/common/header";
import { withTranslation } from "react-i18next";
import NavbarBookmarks from "./NavbarBookmarks";
import { projectContext } from "../../../utility/context/projectSelect";
const ThemeNavbar = (props) => {
  const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
  const navbarTypes = ["floating", "static", "sticky", "hidden"];
  const contextValue = useContext(projectContext);
  // console.log('navbar', props)
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames("header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow", {
          "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
          "navbar-dark": colorsArr.includes(props.navbarColor),
          "bg-primary": props.navbarColor === "primary" && props.navbarType !== "static",
          "bg-danger": props.navbarColor === "danger" && props.navbarType !== "static",
          "bg-success": props.navbarColor === "success" && props.navbarType !== "static",
          "bg-info": props.navbarColor === "info" && props.navbarType !== "static",
          "bg-warning": props.navbarColor === "warning" && props.navbarType !== "static",
          "bg-dark": props.navbarColor === "dark" && props.navbarType !== "static",
          "d-none": props.navbarType === "hidden" && !props.horizontal,
          "floating-nav": (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
          "navbar-static-top": props.navbarType === "static" && !props.horizontal,
          "fixed-top": props.navbarType === "sticky" || props.horizontal,
          scrolling: props.horizontal && props.scrolling,
        })}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div className="navbar-collapse d-flex justify-content-between align-items-center" id="navbar-mobile">
              <Row className="navbar-collapse d-flex justify-content-between align-items-center">
                {/*<Col>*/}
                {/*    <h2>IoT 유지관리시스템</h2>*/}
                {/*</Col>*/}
                <div className="bookmark-wrapper">
                  <NavbarBookmarks sidebarVisibility={props.sidebarVisibility} handleAppOverlay={props.handleAppOverlay} />
                </div>
                <Col>
                  <Header authCode={contextValue.state.msgHeaderCode} refresh_flag={contextValue.state.refresh_flag} />
                </Col>
              </Row>
              {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                  <h2 className="text-primary brand-text mb-0">MYTHINGS</h2>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  );
};

export default withTranslation()(ThemeNavbar);
