import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import useInput from "../../hooks/useInput";
import { Phone, X } from "react-feather";
import axios from "axios";
import { ContactToggle, ContactWrap } from "./style";

const Contact = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, onChangeName, setName] = useInput("");
  const [tel, onChangeTel, setTel] = useInput("");
  const [email, onChangeEmail, setEmail] = useInput("");
  const [message, onChangeMessage, setMessage] = useInput("");
  const [account, onChangeAccount, setAccount] = useInput("");

  const onToggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const onSend = useCallback(() => {
    if (name.trim() === "" || tel.trim() === "" || email.trim() === "" || message.trim() === "" || account.trim() === "") {
      window.alert(t("contact_input_require_msg"));
      return;
    }

    let emailReg = /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!emailReg.test(email)) {
      window.alert(t("user_email_reg_msg"));
      return false;
    }

    let telReg = /^\d{2,3}-\d{3,4}-\d{4}$/;
    if (!telReg.test(tel)) {
      window.alert(t("user_tel_reg_msg"));
      return false;
    }

    setLoading(true);
    let formData = new FormData();
    formData.append("이름", name);
    formData.append("연락처", tel);
    formData.append("이메일", email);
    formData.append("계정정보", account);
    formData.append("메시지", message);

    axios
      .post("https://script.google.com/macros/s/AKfycby45jXqIZWtoNqyKsGOKe1VCSYVxP-D9ChIhEpR8hYk7I-q-1SeG0sQtxOhuoAWlDHL/exec", formData)
      .then((response) => {
        // console.log("response", response);
        window.alert(`${t("contact_send_msg_1")}\n${t("contact_send_msg_2")}`);
        setName("");
        setTel("");
        setEmail("");
        setMessage("");
        setAccount("");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        window.alert(t("contact_send_failure_msg"));
        setLoading(false);
      });
  }, [name, tel, email, message, account, t, setName, setTel, setEmail, setMessage, setAccount]);

  return (
    <div className="overflow-hidden">
      contact
      <ContactToggle className="bg-success" onClick={onToggleOpen} open={open} title={t("contact")}>
        <Phone size={18} />
      </ContactToggle>
      <ContactWrap open={open}>
        <div className="p-3">
          <div className="d-flex justify-content-between mb-3">
            <h2>{t("contact")}</h2>
            <div className="close-icon cursor-pointer" onClick={onToggleOpen}>
              <X size={24} />
            </div>
          </div>
          <form className="gform">
            <div className="d-flex flex-wrap">
              <Col md="12">
                <FormGroup row>
                  <Col md="12">
                    <span>{t("contact_name")}</span>
                  </Col>
                  <Col md="12">
                    <Input type="text" name="name" value={name} onChange={onChangeName} />
                  </Col>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup row>
                  <Col md="12">
                    <span>{t("contact_tel")}</span>
                  </Col>
                  <Col md="12">
                    <Input type="text" name="tel" placeholder={"000-0000-0000"} value={tel} onChange={onChangeTel} />
                  </Col>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup row>
                  <Col md="12">
                    <span>{t("contact_account")}</span>
                  </Col>
                  <Col md="12">
                    <Input type="text" name="account" value={account} onChange={onChangeAccount} />
                  </Col>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup row>
                  <Col md="12">
                    <span>{t("contact_email")}</span>
                  </Col>
                  <Col md="12">
                    <Input type="email" name="email" value={email} onChange={onChangeEmail} />
                  </Col>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup row>
                  <Col md="12">
                    <span>{t("contact_message")}</span>
                  </Col>
                  <Col md="12">
                    <Input placeholder={t("contact_message_info")} type="textarea" name="message" value={message} rows={10} onChange={onChangeMessage} />
                  </Col>
                </FormGroup>
              </Col>
              <Col md="12">
                <Button.Ripple color="primary" onClick={onSend} disabled={loading}>
                  {loading && (
                    <>
                      <Spinner size="sm" />
                      &nbsp;&nbsp;&nbsp;
                    </>
                  )}
                  {t("contact_submit_btn")}
                </Button.Ripple>
              </Col>
            </div>
          </form>
        </div>
      </ContactWrap>
    </div>
  );
};

export default Contact;
