import React from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import classnames from "classnames";
import { ReactComponent as DidIcon } from "../../../assets/icons/did/cast.svg";
import { ApiService } from "../../../service/service.rest";
import { projectContext } from "../../../utility/context/projectSelect";

import "../../../assets/css/base.css";
import { Button, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import ConfirmModal from "../area/ConfirmModal";
import {Info} from "react-feather";

let userValidTimer = null;

const DidContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px 6px 10px;
  width: 75px;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
`;

const DidText = styled.div`
  font-family: Inter;
  margin-left: 6px;
  color: #717171;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

class Header extends React.Component {
  state = {
    contextState: {
      project: 0,
    },
    areaList: [],
    areaInfo: {},
    ruleGroupList: [],
    selectedRuleGroupInfo: null,
    user_info: {},
    areaAuthAlert: false,
    alertMsg: "",
    confirmModal: {
      isOpen: false,
      message: "",
      buttonTitle: "",
      type: "",
      command: "",
    },
    ruleChangePermission: false,
  };
  static contextType = projectContext;

  componentDidMount = () => {
    const t = this.props.t;
    this.userValid();
    this.refreshAreaList();
    this.areaRuleList();
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.authCode !== prevProps.authCode) {
      let areaAuthAlert = false;
      let alertMsg = "";
      switch (this.props.authCode) {
        case "0000":
        case "0001":
          break;
        case "1002":
          areaAuthAlert = true;
          alertMsg = "해당 작업에 권한이 없습니다.";
          this.setState({ areaAuthAlert: areaAuthAlert, alertMsg: alertMsg });
          break;
        default:
          areaAuthAlert = true;
          alertMsg = "일시적인 문제가 발생하였습니다.";
          this.setState({ areaAuthAlert: areaAuthAlert, alertMsg: alertMsg });
          break;
      }
    }
    if (this.context.state.refresh_flag !== prevProps.refresh_flag) {
      this.refreshAreaList();
    }
    const contextValue = this.context;
    if (contextValue.state.project !== this.state.contextState.project) {
      this.setState({ contextState: contextValue.state }, () => {
        this.areaRuleList();
      });
    }
  };
  componentWillUnmount = () => {
    clearTimeout(userValidTimer);
  };
  refreshAreaList = () => {
    const contextValue = this.context;
    ApiService.api_area_list().then(
      (response) => {
        if (response.code === "0000") {
          this.setState(
            {
              areaList: response.data,
            },
            () => {
              if (localStorage.getItem("projectArea")) {
                const localProjectArea = Number(JSON.parse(localStorage.getItem("projectArea")));
                const findArea = this.state.areaList.find((area) => area.area_idx === localProjectArea);
                if (findArea) {
                  contextValue.selectProject(localProjectArea, "", findArea.area_role_idx);
                } else {
                  localStorage.removeItem("uToken");
                  localStorage.removeItem("projectArea");
                  this.props.history.push("/login");
                }
              } else if (!contextValue.state.project) {
                if (this.state.areaList.length > 0) {
                  contextValue.selectProject(this.state.areaList[0].area_idx, "", this.state.areaList[0].area_role_idx);
                  localStorage.setItem("projectArea", JSON.stringify(this.state.areaList[0].area_idx));
                }
              }
            }
          );
        }
      },
      (error) => {
        console.log(error);
        if (error.code === "2001") {
          localStorage.removeItem("uToken");
          this.props.history.push("/login");
        }
      }
    );
  };
  userValid = () => {
    const contextValue = this.context;
    clearTimeout(userValidTimer);
    ApiService.api_valid().then(
      (response) => {
        // console.log('api_valid', response.data);
        if (response.code === "2001") {
          localStorage.removeItem("uToken");
          this.props.history.push("/login");
        }
        if (response.code === "0000") {
          this.setState({
            user_info: response.data.user_info,
          });
          contextValue.setUserInfo(response.data.user_info);
          userValidTimer = setTimeout(() => {
            this.userValid();
          }, 1000 * 60 * 60);
          if (response.data.user_info.role_idx > 0) {
            ApiService.api_role_menu_list(response.data.user_info.role_idx).then(
              (response) => {
                if (response.code === "0000") {
                  if (response.data.find((item) => item.menu_idx === 5)) {
                    this.setState({ ruleChangePermission: true });
                  }
                }
              },
            )
          }
        }
      },
      (error) => {
        console.log(error);
        if (error.code === "2001") {
          localStorage.removeItem("uToken");
          this.props.history.push("/login");
        }
      }
    );
  };
  selectArea = (area_idx, dataset) => {
    const contextValue = this.context;
    contextValue.selectProject(area_idx, "", dataset.roleIdx);
    localStorage.setItem("projectArea", JSON.stringify(area_idx));
  };

  areaRuleList = () => {
    const t = this.props.t;
    const context = this.state.contextState.project;
    ApiService.api_rule_group_list(context).then(
      (response) => {
        if (response.code === "0000") {
          this.setState(
          {ruleGroupList: response.data},
          () => {this.callAreaDetailInfo();}
          )
        }
      },
      (error) => {
        t("common_list_error_msg");
      }
    );
  };

  onFinishConfirmPopup = () => {
    this.setState({ areaAuthAlert: false });
  };

  openConfirmModal = (selectedRuleGroupInfo) => {
    const t = this.props.t;

    if (this.state.ruleGroupList.find((item) => item.use_yn === 'Y').rule_group_id !== selectedRuleGroupInfo) {
      this.setState({
        selectedRuleGroupInfo: selectedRuleGroupInfo,
        confirmModal: {
          ...this.state.confirmModal,
          isOpen: true,
          message: `${t("rule_group_change_drive_msg1")},\n${t("rule_group_change_drive_msg2")}\n(${t("rule_group_change_drive_msg3")})\n${t("rule_group_change_drive_msg4")}`,
          buttonTitle: t("rule_group_run_change"),
          type: "rule",
          command: "confirm",
        },
      });
    } else {
      return;
    }
  };

  onClickConfirmModal = (type, command) => {
    if (type === "rule" && command === "confirm") {
      this.callChangeRule(this.state.selectedRuleGroupInfo);
    } else {
      this.setState({
        confirmModal: {
          ...this.state.confirmModal,
          isOpen: false,
        },
      });
    }
  }

  callChangeRule = (ruleGroupInfo) => {
    const t = this.props.t;

    const context = this.state.contextState.project;
    const changeRuleGroupInfo = this.state.ruleGroupList.find((item) => item.rule_group_id === ruleGroupInfo);
    ApiService.api_rule_group_use(context, changeRuleGroupInfo.rule_group_id, changeRuleGroupInfo.use_yn).then(
      (response) => {
        if (response.code === "0000") {
          this.setState({
            confirmModal: {
              ...this.state.confirmModal,
              isOpen: false,
            },
          });
          this.areaRuleList();
          window.location.reload();
        } else {
          t("common_error_msg");
        }
      },
      (error) => {
        if (error.code === "1002") {
          this.setState({
            confirmModal: {
              ...this.state.confirmModal,
              isOpen: false,
            },
          })
          window.alert(t("alert_unauthorized_role_msg"));
        }
        t("common_error_msg");
      }
    );
  }

  callAreaDetailInfo = () => {
    const context = this.state.contextState.project;
    ApiService.api_area_detail(context).then(
      (response) => {
        if (response.code === "0000") {
          this.setState({
            ...this.state,
            areaInfo: response.data,
          });
          localStorage.setItem("projectAreaName", JSON.stringify(response.data.area_name));
        }
      },
      (error) => {
        if (error.code === "2001") {
          localStorage.removeItem("uToken");
        }
      }
    );
  }

  render() {
    const t = this.props.t;

    if (window.location.pathname === "/login") return null;
    const contextValue = this.context;

    let areaList = this.state.areaList.map((item, i) => {
      return (
        <option key={"al" + i} value={item.area_idx} data-role-idx={item.area_role_idx}>
          {item.area_name}
        </option>
      );
    });

    let activeRuleGroup = this.state.ruleGroupList.find((item) => item.use_yn === 'Y');

    return (
      <>
        <Row className="align-items-center justify-content-end">
          {this.state.areaInfo.use_yn === "N" && (
            <Col md="5" sm="5" xs="5" className="d-flex align-items-center">
              <Info className="mr-1" size="25" color="red" />
              <span style={{color: "red", fontSize: "1.5rem", fontWeight: "bold"}}>{t("unused_area")}</span>
            </Col>
          )}
          <Col md="3" sm="3" xs="3" className={(this.state.user_info.role_idx === 1 || this.state.user_info.role_idx === 2 || this.state.user_info.role_idx === 3) ? 'pr-0' : 'pr-1'}>
            <Input
              type="select"
              name="select"
              value={contextValue.state.project}
              onChange={(e) => {
                  this.selectArea(e.target.value, e.target.options[e.target.selectedIndex].dataset)
                  window.location.reload();
                }
              }
            >
              {areaList}
            </Input>
          </Col>
          {this.state.ruleChangePermission === true && (
            <Col md="2" sm="2" xs="2">
              <Input
                type="select"
                name="select"
                value={activeRuleGroup ? activeRuleGroup.rule_group_id : ""}
                onChange={(e) => {
                  this.openConfirmModal(e.target.value);
                }}
              >
                {this.state.ruleGroupList.map((info) => {
                  return (
                    <option value={info.rule_group_id} key={info.rule_group_id}>
                      {info.rule_group_name}
                    </option>
                  );
                })}
              </Input>
            </Col>
          )}
          <Col md="1" sm="1" xs="1" className={classnames("pr-0 ali", {"pl-1" : this.ruleChangePermission === true}, {"pl-0": this.ruleChangePermission === false})}>
            {this.state.user_info.user_name}
          </Col>
          <Col md="1" sm="1" xs="1" className="pl-0 pr-0">
            <Link to={`/did/${contextValue.state.project}/1`} target="_self" className="secondary">
              <DidContainer>
                <DidIcon />
                <DidText>
                  DID
                </DidText>
              </DidContainer>
            </Link>
          </Col>
        </Row>
        {this.state.areaAuthAlert && (
          <Modal isOpen={this.state.areaAuthAlert} className="modal-dialog-centered">
            <ModalHeader toggle={() => this.onFinishConfirmPopup()} className="bg-primary" />
            <ModalBody className="modal-dialog-centered">{this.state.alertMsg}</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => this.onFinishConfirmPopup()}>
                확인
              </Button>
            </ModalFooter>
          </Modal>
          // <div className="layer-popup">
          //     <div>
          //         <p>
          //             {this.state.alertMsg}
          //         </p>
          //         <div className="set-btn">
          //             <button data-title="확인" onClick={() => this.onFinishConfirmPopup()}></button>
          //         </div>
          //     </div>
          // </div>
        )}
        {/*룰 변경 모달*/}
        <ConfirmModal
          isOpen={this.state.confirmModal.isOpen}
          message={this.state.confirmModal.message}
          buttonTitle={this.state.confirmModal.buttonTitle}
          type={this.state.confirmModal.type}
          command={this.state.confirmModal.command}
          onClickClose={this.onClickConfirmModal}
        />
      </>
    );
  }
}

export default withRouter(withTranslation()(Header));
