import styled from "styled-components";

export const ContactWrap = styled.div`
  position: absolute;
  overflow-x: hidden;
  display: ${(props) => (props.open ? "block" : "block")};
  right: 0; //${(props) => (props.open ? 0 : "-400px")};
  top: 0;
  width: ${(props) => (props.open ? "400px" : "0")}; //400px;
  height: 100%;
  z-index: 1051;
  background-color: #ffffff;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 15px 30px 0 rgb(0 0 0 / 11%), 0 5px 15px 0 rgb(0 0 0 / 8%);
  transition: all 0.2s ease-in-out;
`;

export const ContactToggle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: ${(props) => (props.open ? "400px" : 0)};
  top: 7.4rem;
  width: 34px;
  height: 34px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  & svg {
    position: relative;
    color: #ffffff;
  }
`;
